.pair {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

/*
 * The size calculation has three possible values: a constant 400px, a computation to handle
 * narrow displays, and a computation to handle short displays so that no scrolling is needed.
 * 
 * Constant calculation:
 * 400px: arbitrary
 * 
 * Narrow display computation:
 * 100vw: starting point
 * -40px: 20px margin on each side
 *
 * Short display computation
 * 100vh: starting point
 * -40px: padding on container
 * -84px: progress indicator is 24px tall, 20px margin above, 40px margin below
 * / 2: now we split for 2 cells
 * -80px: 2 elements, each has top and bottom margins of 20px apiece so 80px of margin total
 */

.element {
    height: max(200px, min(400px, min(calc(100vw - 40px), calc((100vh - 40px - 84px) / 2 - 80px))));
    width: max(200px, min(400px, min(calc(100vw - 40px), calc((100vh - 40px - 84px) / 2 - 80px))));
    border: 8px solid #BBBBBB;
    color: #BBBBBB;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px;
    transition-duration: 200ms;
    font-size: 20px;
    padding: 10px;
    
    -webkit-box-sizing: border-box; 
    -moz-box-sizing: border-box;  
    box-sizing: border-box;         
    
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-word;

    overflow: auto;
}

.highlightedElement {
    border-color: #08BA6A;
    color: #08BA6A;
}

@media (hover: hover) and (pointer: fine) {
    .element:hover {
        border-color: #08BA6A;
        color: #08BA6A;
    }
}