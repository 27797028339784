.titleContainer {
    text-align: center;
}

.title {
    color: white;
    font-size: 60px;
}

.subtitle {
    color: #999999;
    font-size: 28px;
}

.allTiersContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;

    width: 100%;
}

.oneTierContainer {
    color: white;

    display: flex;
    flex: 1;

    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    border: 4px solid #111111;
    padding: 5px;
    margin: 5px;
    border-radius: 20px;

    transition-duration: 200ms;
}

.list {
    min-height: 400px;
    width: max(min(70vw, 400px), 15vw);

    font-size: 32px;

    padding: 20px;
    border-radius: 15px;
    transition-duration: 200ms;

    border: 4px dotted white;

    position: relative;
}

.listDraggedOver {
    background-color: #222222;
}

.listItem {
    width: max(min(70vw, 400px), 15vw);
    overflow-wrap: break-word;
    word-break: break-word;

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 15px 0px;
}

.listItemText {
    transition-duration: 200ms;
}

@media (hover: hover) and (pointer: fine) {
    .listItemText:hover {
        color:#08BA6A;
    }
}

.listItemText:active {
    color:#08BA6A;
}

.selectedListItemText {
    color: #08BA6A;
}

.selectedListItemButOtherItemBeingDragged {
    color: #444444;
}

.draggedItemCount {
    margin-left: 20px;
    color: #999999;

    font-weight: 600;

    font-size: 16px;
    border-bottom: 2px solid #999999;
}

.header {
    font-size: 40px;
    color: white;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}
.dragHandleIcon {
    color: #111111;

    width: 15px;
    height: 18px;

    min-width: 15px;
    min-height: 18px;
    margin-right: 8px;
    margin-top: 11px;

    justify-self: flex-start;
    align-self: flex-start;
}

.dragHandleIcon path {
    fill: #999999;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    flex-wrap: wrap;
}

.deleteIcon {
    transition-duration: 200ms;

    width: 100px;
    text-align: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    color: #999999;

    stroke-width: 0.1px;
}

.deleteIconText {
    font-size: 24px;
}

@media (hover: hover) and (pointer: fine) {
    .deleteIcon:hover {
        color: #C41449;
    }
}
