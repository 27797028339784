html, body {
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-color: #111111;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

input {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

code {
  font-family: Arial, Helvetica, sans-serif;
}

textarea {
  font-family: Arial, Helvetica, sans-serif;
}