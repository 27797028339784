.container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 28px;
}

.title {
    margin-top: 10vh;
    margin-bottom: 20px;
    font-size: 40px;
    text-align: center;
}

.resultsList {
    margin-bottom: 20px;
}

.resultNumber {
    color: #444444;
}

.justification {
    min-height: 32px;
    font-size: 20px;
    text-align: center;
    overflow-wrap: break-word;
    word-break: break-word;
}

.fillerJustification {
    color: #444444;
    font-size: 20px;
    text-align: center;
}

.element {
    margin: 10px;
    color: #BBBBBB;
    overflow-wrap: break-word;
    word-break: break-word;

    justify-self: start;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.selectedElement {
    color: #08BA6A;
}

@media (hover: hover) and (pointer: fine) {
    .element:hover {
        color: #08BA6A;
    }
}
