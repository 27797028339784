.container {
    width: 100%;
    min-height: calc(100vh - 40px);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    overflow-x: hidden;
}

.menu {
    background-color: #444444;
    color: #BBBBBB;
    min-width: 80px;
    padding: 5px;
    font-size: 21px;
}

.title {
    margin-top: 10vh;
    width: 80vw;

    font-weight: 600;
    font-size: 60px;
    text-align: center;
    background-color: #111111;
    outline: none;
    border-width: 0px;
    color: #BBBBBB;
    resize: none;
    overflow: hidden;

    transition-property: color;
    transition-duration: 200ms;

    margin-bottom: 79px;

    font-family: inherit;
}

.textboxAndHelperContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textboxHelper {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;

    transition-duration: 200ms;

    display: flex;
    flex-direction: row;

    align-items: center;

    min-height: 39px;
}

.enabledTextboxHelper {
    color: #999999;
}

.disabledTextboxHelper {
    color: #444444;
}

.textboxContainer {
    width: 100%;
    min-height: calc(35vh - 60px - 10vh);
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;

    margin-bottom: 10px;
}

.textbox {
    min-height: 60px;
    height: auto;
    width: 60vw;
    font-size: 28px;
    text-align: center;
    background-color: #111111;
    outline: none;
    border-width: 0px;
    border-bottom: 5px solid #BBBBBB;
    
    color: #BBBBBB;
    resize: none;
    border-top: none;
    border-left: none;
    border-right: none;

    transition-property: color;
    transition-duration: 200ms;

    overflow: hidden;
}

::placeholder {
    color: #444444;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #444444;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #444444;
}
  
::-ms-input-placeholder { /* IE 10+ */
    color: #444444;
}
  
::-moz-placeholder { /* Firefox 18- */
    color: #444444;
}

@media (hover: hover) and (pointer: fine) {
    .textbox:hover {
        border-bottom: 5px solid #08BA6A;
    }
}

.textbox:focus {
    outline: none;
    border-width: 0px;
    border-bottom: 5px solid #08BA6A;
    color: white;
}

.buttonContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 15px;

    /* need to make margin-bottom 15px less than margin-top since entries have 15px margin above them */
    margin-bottom: 20px;
}

.buttonIconAndTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-width: 120px;
    max-width: 120px;
}

.buttonIconAndTextContainer div {
    transition-duration: 200ms;
}

.disabledButtonIconAndTextContainer {
    color: #444444
}

.enabledButtonIconAndTextContainer {
    color: #BBBBBB;
}

.buttonIconContainer {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    font-size: 20px;
    text-align: center;
    margin: 20px calc(2vw + 10px);

    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonIcon {
    width: 14px;
    height: 14px;
}

.reverseIcon {
    transform: scaleX(-1);
}

.buttonText {
    width: 100%;
    text-align: center;
    font-size: 15px;
    min-height: 38px;
}

.disabledButton {
    border: 2px solid #444444;
}

.enabledButton {
    border: 2px solid #BBBBBB;
}

.enabledDeleteButtonIconAndTextContainer {
    color: #BBBBBB;
}

@media (hover: hover) and (pointer: fine) {
    .enabledButtonIconAndTextContainer:hover {
        color: #08BA6A;
    }

    .enabledButtonIconAndTextContainer:hover .enabledButton {
        border: 2px solid #08BA6A;
    }

    .enabledDeleteButtonIconAndTextContainer:hover {
        color: #C41449;
        ;
    }

    .enabledDeleteButtonIconAndTextContainer:hover .enabledButton {
        border: 2px solid #C41449;
    }
}

svg {
    fill: currentColor;
    stroke: currentColor;
}

.toastSuccessComponentContainer {
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.toastSuccessComponentSVGContainer {
    padding: 0px;
    max-height: 18px;
    max-width: 18px;
    margin-left: 4px;
}

.toastSuccessComponentSVG {
    height: 18px;
    width: 18px;
    color: #444444;
}

@media (hover: hover) and (pointer: fine) {
    .toastSuccessComponentSVG:hover {
        color: #111111;
        transition-duration: 200ms;
    }
}

.entryContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */

    margin-top: 20px;
}

.entry {
    font-size: 25px;
    color: #BBBBBB;
    margin-top: 15px;
    margin-left: 50px;
    margin-bottom: 15px;
    margin-right: 50px;

    max-width: calc(100vw - 100px);
    text-align: center;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    overflow-wrap: break-word;
    word-break: break-word;

    transform: translateX(30px);

    transition-duration: 200ms;
}

@media (hover: hover) and (pointer: fine) {
    .entry:hover {
        color: #C41449;
    }
}

.svgDeleteIcon {
    display: inline-block;
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    margin: 10px 20px;
}

  
.svgDeleteIcon path,
.svgDeleteIcon polygon,

.svgDeleteIcon circle {
    stroke: #444444;
    stroke-width: 1.25px;
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
}