.container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
    padding: 20px;
}

.headerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    color: #BBBBBB;
    font-size: 50px;
    margin-bottom: 10px;
    text-align: center;
}

.subtitle {
    color: #999999;
    font-size: 20px;
    text-align: center;
}

.progressIndicator {
    margin: 0px 5px;
    color: #BBBBBB;
    font-size: 20px;
}