.copyLinkContainer {
    color: #999999;
    background-color: #111111;
    opacity: 1;
    border-radius: 15px;
    margin-bottom: 10px;

    padding: 35px; 
    border: 3px dotted #999999;

    max-width: 50vw;
}

.copyLinkHeader {
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 20px;

    color: white;
    max-width: 90vw;
}

.copyLinkTextAndBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.copyLinkBar {
    overflow: hidden;
    font-size: 21px;
    padding-bottom: 10px;

    color: #999999;
    background-color: #111111;
    outline: none;

    padding-top: 10px;

    /* max-height: 25px; */

    max-width: calc(50vw - 35px);

    resize: none;
    border: none;
}

.copyLinkBarInput {
    border-top: none;
    border-bottom: 3px solid #08BA6A;
}

.copyLinkBarResults {
    border-bottom: none;
    border-top: 3px solid #08BA6A;;
}

.loadingCopyLinkBar {
    color: #444444;
}

.copyLinkButton {
    margin-left: 10px;
}

.copyLinkButton svg {
    width: 24px;
    height: 24px;

    transition-duration: 200ms;
}

.enabledCopyLinkButton svg {
    fill: #999999;
    stroke: #999999;
}

.disabledCopyLinkButton svg {
    fill: #444444;
    stroke: #444444;
}

@media (hover: hover) and (pointer: fine) {
    .enabledCopyLinkButton svg:hover {
        fill: #08BA6A;
        stroke: #08BA6A;
    }
}